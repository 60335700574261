// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {  production: false,  firebaseConfig: {    apiKey: 'AIzaSyAsbGzXPSueAOLqLrAqhPwi6e79G2yEq1o',    authDomain: 'poccomu-693c3.firebaseapp.com',    databaseURL: 'https://poccomu-693c3.firebaseio.com',    projectId: 'poccomu-693c3',    storageBucket: 'poccomu-693c3.appspot.com',    messagingSenderId: '62045753375',    appId: '1:62045753375:web:f128d228078c492b1a452a',    measurementId: 'G-4Y88NSQ4WD'  }};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
