import {Injectable} from '@angular/core';
import {AppStorage} from './appstorage';
import {Constants} from '../../utils/constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {cfaSignIn} from 'capacitor-firebase-auth';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class HttpProvider {
    tokenId: any;

    constructor(private http: HttpClient, private storage: AppStorage) {
    }
    callRestApi(api: string, data: any): Promise<any> {
        return new Promise<any>(resolve => {
            //this.storage.getValue(Constants.TOKEN).then(token => {
                //this.tokenId = token;
                data[`token`] = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IlF2WXJROG1IZExLM2FKMFB1ZCtGYVdLNm0wb0pOY0J1ZXdxR0pNNXRhcWdNV0I1OGZaOHR3M3dJSXl3dUZVN28yeUxhdGltNzBzaDhDbnFSMCtSd2s2dUN2QTMybjBOb1haNk9zbVpwczdlZXRnUTIvdjlQRXZ2Q0dCdEUvVksyeTZ1dWxaWnNjRHZpdkdNV24xYXNnN3BWM01ob1BwcDEwRjhmVjh6N3dhbGdKcVNnYWErczRKbkxFNElHZW9heDlIV2llMlVRVlF2NGFnOWFwcW12aEZicmdYQ0JVSmEvUjBHaENkd21DRGM9IiwiaWF0IjoxNjI1NjM4MDYxLCJleHAiOjE2NTcxNzQwNjF9.ptum8VN7sqysIMPY4oVXhZmieJFdO3kt9oP4Z-7UGSU';
                console.log(JSON.stringify(data));
                // tslint:disable-next-line:max-line-length
                const apiCall = this.http.post('https://mkwavcscdh.execute-api.us-east-1.amazonaws.com/prod/' + api, JSON.stringify(data)).subscribe(resp => {
                    apiCall.unsubscribe();
                    resolve(resp);
                }, error => resolve({status: Constants.HTTP_ERROR}));
            //});
        });
    }
// https://380e5i0rla.execute-api.us-east-1.amazonaws.com/dev/
 //https://mkwavcscdh.execute-api.us-east-1.amazonaws.com/prod/



    getLocationFromLatLng(lat: number, lng: number): Promise<any> {
        return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAD8olpb2GCNqxdynP1N4Z9_hP3sIewzGw`).toPromise();
    }
    socialLogin(provider: string): Observable<any> {
        return cfaSignIn(provider).pipe(
            catchError(err => of(err))
        );
    }

    /////////////////////////////////////////new API//////////////////////////////////////////////////////////////////////////////

    // callRestApiTests(api: string, data: any): Promise<any> {
    //     return new Promise<any>(resolve => {
    //         this.storage.getValue(Constants.TOKEN).then(token => {
    //             //this.tokenId = token;
    //             //data[`token`] = (this.tokenId == null) ? 1 : this.tokenId;
    //             console.log(JSON.stringify(data));
    //             // tslint:disable-next-line:max-line-length
    //             const apiCall = this.http.post('https://380e5i0rla.execute-api.us-east-1.amazonaws.com/dev/' + api, JSON.stringify(data)).subscribe(resp => {
    //                 apiCall.unsubscribe();
    //                 resolve(resp);
    //             }, error => resolve({status: Constants.HTTP_ERROR}));
    //         });
    //     });
    // }

    newCallRestApi(api: string, data: any): Promise<any> {
        return new Promise<any>(resolve => {
            this.storage.getValue(Constants.TOKEN).then(token => {
                this.tokenId = token;
                //data[`token`] = (this.tokenId == null) ? 1 : this.tokenId;
                const token_all = (this.tokenId == null) ? 1 : this.tokenId;
                const headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept' : '*/*',
                    'authorization': token_all
                });
                const options = {
                    headers: headers
                };
                console.log(JSON.stringify(data));
                // tslint:disable-next-line:max-line-length
                //http://65.1.59.116/
                //https://api.16biticon.com/
                // https://kb14ts4z68.execute-api.us-east-1.amazonaws.com/
                const apiCall = this.http.post('https://kb14ts4z68.execute-api.us-east-1.amazonaws.com/dev/' + api, JSON.stringify(data), options).subscribe(resp => {
                    apiCall.unsubscribe();
                    resolve(resp);
                }, error => resolve({status: Constants.HTTP_ERROR}));
            });
        });
    }

    newCallApiGet(action) {
        return new Promise((resolve, reject) => {
            this.storage.getValue(Constants.TOKEN).then(count => {
                this.tokenId = count;
                //console.log('Token Id --', this.tokenId);
                let header = new HttpHeaders({"authorization": this.tokenId});
                const requestOptions = {headers: header};
                return this.http.get<any>('https://kb14ts4z68.execute-api.us-east-1.amazonaws.com/dev/' + action, requestOptions).toPromise()
                    .then(data => {
                        //console.log('get data--', data);
                        resolve(data)
                    })
                    .catch((err) =>{
                        console.log(err)
                        reject(err)
                    })
            });
        })
    }

}
