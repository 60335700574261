import {Injectable} from '@angular/core';
import {Plugins, StatusBarStyle} from '@capacitor/core';
import {environment} from '../../environments/environment';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {filter} from 'rxjs/operators';
import '@capacitor-community/firebase-analytics';

const {FirebaseAnalytics, Device} = Plugins;

@Injectable({
    providedIn: 'root'
})
export class MarketingService {

    constructor(private router: Router) {
        this.initFB();
        this.router.events.pipe(
            filter((e: RouterEvent) => e instanceof NavigationEnd),
        ).subscribe((e: RouterEvent) => {
            console.log('Router change', e.url);
            this.setScreenName(e.url);
        });
    }

    async initFB() {
        if ((await Device.getInfo()).platform === 'web') {
            FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
        }
    }

    setUser() {
        // User Firebase Auth uid
        FirebaseAnalytics.setUserId({
            userId: ''
        });
    }

    setScreenName(screenName) {
        FirebaseAnalytics.setScreenName({
            screenName
        });
    }

    setProperty() {
        FirebaseAnalytics.setProperty({
            name: '',
            value: ''
        });
    }

    logEvent() {
        FirebaseAnalytics.logEvent({
            name: 'login',
            params: {
                method: 'login'
            }
        });
    }

    regEvent() {
        FirebaseAnalytics.logEvent({
            name: 'sign_up',
            params: {
                method: 'sign_up'
            }
        });
    }

    tutorialBeginEvent() {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_begin'
        });
    }

    tutorialCompleteEvent() {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_complete'
        });
    }


    tutorialCompleteEventMath(SubjectName) {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_complete_Math / ' + SubjectName
        });
        //console.log('tutorial_complete_Math / ' + SubjectName)
    }

    tutorialCompleteEventBasicQuestions(SubjectName,ChapterName,examName) {
        FirebaseAnalytics.logEvent({
            name: 'complete_Basic_Qus./'+ SubjectName +'/'+ ChapterName +'/'+examName
        });
        console.log('basic');
    }

    tutorialCompleteEventAdvancedQuestions(SubjectName,ChapterName,examName) {
        FirebaseAnalytics.logEvent({
            name: 'complete_Advance_Qus./'+ SubjectName +'/'+ ChapterName +'/'+examName
        });
        console.log('advance');
    }

    moduleNameEvent(module_name,chapter_name) {
        FirebaseAnalytics.logEvent({
            name: 'module/chapter_name: '+ module_name +'/'+ chapter_name
        });
        console.log('module/chapter_name: ', module_name +'/'+ chapter_name);
    }

    subjectNameEvent(SubjectName) {
        FirebaseAnalytics.logEvent({
            name: 'subject_name : '+ SubjectName
        });
        console.log('subject_name : ', SubjectName);
    }


    moduleNameOneEvent(module_name) {
        FirebaseAnalytics.logEvent({
            name: 'module_name : '+ module_name
        });
        console.log('module_name : ', module_name);
    }

    enrollSubjectNameEvent(SubjectName){
        FirebaseAnalytics.logEvent({
            name: 'enroll subject name: '+ SubjectName
        });
        console.log('enroll subject name: ', SubjectName);
    }

    tutorialCompleteEventPhysics(SubjectName) {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_complete_Physics / ' + SubjectName
        });
    }
    tutorialCompleteEventComputerScience(SubjectName) {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_complete_Computer_Science / ' + SubjectName
        });
    }
    tutorialCompleteEventBiology(SubjectName) {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_complete_Biology / ' + SubjectName
        });
    }
    tutorialCompleteEventChemistry(SubjectName) {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_complete_Chemistry / ' + SubjectName
        });
    }

    tutorialBeginEventMath() {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_begin_Math'
        });
    }

    tutorialBeginEventPhysics() {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_begin_Physics'
        });
    }

    tutorialBeginEventComputerScience() {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_begin_Computer_Science'
        });
    }
    tutorialBeginEventChemistry() {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_begin_Chemistry'
        });
    }
    tutorialBeginEventBiology() {
        FirebaseAnalytics.logEvent({
            name: 'tutorial_begin_Biology'
        });
    }

    paymantBeginEvent() {
        FirebaseAnalytics.logEvent({
            name: 'payment_begin'
        });
    }

    paymantCompleteEventMonth() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_monthly'
        });
    }

    paymantCompleteYear() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_premium_plan'
        });
    }
    paymantCompletePro() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_pro_plan'
        });
    }
    paymantCompleteLite() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_lite_plan'
        });
    }
    ////////////////////////////////////////////////////////////////
    paymantCompleteEventMonthMath() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_monthly_Math'
        });
    }
    paymantCompleteEventMonthPhysics() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_monthly_Physics'
        });
    }
    paymantCompleteEventMonthComputerScience() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_monthly_Computer_Science'
        });
    }
    paymantCompleteEventMonthChemistry() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_monthly_Chemistry'
        });
    }
    paymantCompleteEventMonthBiology() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_monthly_Biology'
        });
    }
    ////////////////////////////////////////////////////////////////
    paymantCompleteEventYearly() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_yearly'
        });
    }
    ////////////////////////////////////////////////////////////////
    paymantCompleteEventYearlyMath() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_yearly_Math'
        });
    }
    paymantCompleteEvenYearlyPhysics() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_yearly_Physics'
        });
    }
    paymantCompleteEventYearlyComputerScience() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_yearly_Computer_Science'
        });
    }
    paymantCompleteEventYearlyChemistry() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_yearly_Chemistry'
        });
    }
    paymantCompleteEventYearlyBiology() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_yearly_Biology'
        });
    }
    ////////////////////////////////////////////////////////////////

    paymantCompleteEventOneTime() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_onetime'
        });
    }
    /////////////////////////////////////////////////////////////////
    paymantCompleteEventOneTimeMath() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_one_time_Math'
        });
    }
    paymantCompleteEventOneTimePhysics() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_one_time_Physics'
        });
    }
    paymantCompleteEventOneTimeComputerScience() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_one_time_Computer_Science'
        });
    }
    paymantCompleteEventOneTimeChemistry() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_one_time_Chemistry'
        });
    }
    paymantCompleteEventOneTimeBiology() {
        FirebaseAnalytics.logEvent({
            name: 'payment_complete_one_time_Biology'
        });
    }
    ////////////////////////////////////////////////////////////////

    shareEvent() {
        FirebaseAnalytics.logEvent({
            name: 'share',
            content_type: 'Poccomu is a repository of elite standard question papers for every week. Poccomu releases ten tough questions on Mathematics and Physics as a 30 minutes test paper.',
            item_id: ''
        });
    }
}
