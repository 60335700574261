export class Constants {
  public static SUCCESS: any = false;
  public static NOT_FOUND: any = 404;
  public static WRONG: any = 401;
  public static HTTP_ERROR: any = 400;
  public static USER_ID: any = 'USER_ID';
  public static USER_NAME: any = 'user_name';

  public static USER_Email: any = 'user_email';
  public static USER_Phone: any = 'user_phone';
  public static USER_OrderID: any = 'user_orderid';

  public static USER_CountryName: any = 'user_country_name';

  public static USER_Questions: any = 'questions';


  public static USER_IMG_NAME: any = 'user_img';
  public static USER_TYPE: any = 'user_type';
  public static APPFCM_ID: any = 'fcm_id';
  public static TOKEN: any = 'token';
  public static COLLEGENAME : any = 'college_name';

  public static AllSubjects: any = 'all_subjects';

  public static FCMTOKEN: any = 'fcm_token';
  public static UUID: any = 'uuid';
  public static ExamDuration: any = 'exam_duration';
  public static SubjectId: any = 'subject_id';
  public static SubjectName: any = 'subject_name';

  public static ModuleNameE: any = 'module_name';
  public static ModuleIDE: any = 'module_id';


  public static ExamId: any = 'exam_id';
  public static QusId: any = 'qus_id';

  public static ModuleId: any = 'module_id';
  public static ModuleName: any = 'module_name';
  public static ChapterName: any = 'chapter_name';
  public static ChapterId: any = 'chapter_id';
  public static isAdvanced: any = 'is_advanced';

  public static userAttempt: any = 'user_attempt';
  public static userExamStatus: any = 'exam_status';
  public static Subscribed: any = 'exam_status';
  public static examName: any = 'exam_name';
  public static examName2: any = 'exam_name2';
  public static isRendering: any = 'is_rendering ';

  public static EMAIL_ALREADY_EXISTS: any = 100;
  public static PHONE_ALLREADY_REGISTER: any = 185;
  public static PLEASE_VERIFIED_OTP: any = 165;
  public static OTP_EXPIRE: any = 125;
  public static OTP_MISMATCH: any = 102;
  public static EITHER_EMAIL_OR_PASSWORD_WRONG: any = 103;

  public static USER_IMG_URL: any = 'https://cracking-it-candidate-profile.s3.amazonaws.com/';

  public static REF_ID: any = 'ref_id';
  public static MOBILE: any = 'ph_no';
  public static SEX: any = 'M';
  public static AGE: any = 18;
  public static ROLE: any = 0;
  public static HEIGHT: any = 'HEIGHT';
  public static LAB_ID: any = 'LAB_ID';
  public static WEIGHT: any = 'WEIGHT';
  public static DESEASE: any = 'DESEASE';
  public static ALERGY: any = 'ALERGY';
  public static HABITS: any = 'HABITS';
  public static AADHAR: any = 'SDSGDGSF5235632GDWVGEWT12332DFF3E34';
  public static EMAIL: any = 'email';
  public static PATIENT_ID: any = 0;
  public static ADDRESS: any = 'PATIENT_ID';
  public static PINCODE: any = 'pin_code';
  public static Landmark: any = 'landmark';
  public static selectedQuestions: any;

  // validator regex
  public static NAME_REGEX = /^[a-zA-Z\s]*$/;
  public static PHONE_REGEX = /^(?!(\d)\1+$)(?:\(?\+\d{1,3}\)?[]?|0)?\d{10}$/;
  public static PIN_REGEX = /^\d{6}$/;
  public static COUNTRY_REGEX =  /^[0-9]*$/;
  public static EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static PASSWORD = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@#^$!%*?&])[A-Za-z\d$@#^$!%*?&]{8,}?/;
  public static OPTED_IN = 'OptedIn';
  public static ID = 'id';
  public static NAME = 'name';
  public  static GOOGLEID = 'googleId';
  public  static FACEBOOK = 'facebookId';
}
