export const environment = {

    production: true,

    firebaseConfig: {

        apiKey: 'AIzaSyAsbGzXPSueAOLqLrAqhPwi6e79G2yEq1o',

        authDomain: 'poccomu-693c3.firebaseapp.com',

        databaseURL: 'https://poccomu-693c3.firebaseio.com',

        projectId: 'poccomu-693c3',

        storageBucket: 'poccomu-693c3.appspot.com',

        messagingSenderId: '62045753375',

        appId: '1:62045753375:web:f128d228078c492b1a452a',

        measurementId: 'G-4Y88NSQ4WD'

    }

};
