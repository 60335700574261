
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // remove name
    var body = JSON.parse(req.body);
        
    if(body && body.token && (body.token==1 || body.token==null)){
      delete body.token
  }
    JSON.stringify(body)
    var reqCopy = req.clone({
        body
    })
   
    return next.handle(reqCopy);
  }
}